<template>
  <div class="missions-preview-graph">
    <CCard class="zq--wizard-card overflow-hidden">
      <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
        <div class="d-flex justify-content-between">
          <strong class="title">{{ 'Mission Graph' }}</strong>
          <CLink class="card-header-action btn-minimize">
            <ClCardArrow :is-collapsed="basicIsCollapsed"/>
          </CLink>
        </div>
      </CCardHeader>
      <CCollapse :show="basicIsCollapsed" :duration="400">
        <CCardBody>
          <div
            ref="graphContainer"
            class="graph-container"
            @wheel="onMouseWheel"
            @mousedown="onDragStart"
            @mousemove="onDragging"
            @mouseup="onDragEnd"
          ></div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <Modal
      :modalShow="isShowModal"
      :messageGeneral="messageGeneral"
      :title="modalTitle"
      @doFunction="goToAchievement"
      v-on:toggle-modal="closeModal"
      @closeModal="closeModal"
      :isSuccessButton="isModalSuccessButton"
      :successBtnLabel="'Go to Step'"
    />
  </div>
</template>

<script>
import * as d3 from 'd3';
import ClCardArrow from '@/shared/UI/ClCardArrow.vue';
import { mapActions } from 'vuex';
import Modal from '@/shared/UI/Modal.vue';
import { dateFormate } from '@/utils/dateFormate';
import { htmlWithStyles } from '@/components/mission/missionBlock';

export default {
  name: 'MissionsGraph',
  components: { Modal, ClCardArrow },
  props: {
    graphData: Object,
    missionsData: Array,
    isMissionPreview: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      basicIsCollapsed: this.isMissionPreview,
      iconsUri: {},
      isShowModal: false,
      isModalSuccessButton: true,
      modalTitle: '',
      messageGeneral: '',
      edgeTypeColors: {
        'MUST': '#3bb54c',
        'SHOULD': '#f48f3b',
        'MUST-NOT': '#e74a39'
      },
      entityId: null,
      scale: 1,
      isDragging: false,
      dragStartCoords: { x: 0, y: 0 },
      nodeWidth: 200,
      nodeHeight: 80,
      nodeSelection: null,
    };
  },
  async mounted() {
    await this.loadIcons();
    await this.createGraph();
  },
  methods: {
    ...mapActions('files', ['handleGetFileObjects_item']),
    async createGraph() {

      d3.select(this.$refs.graphContainer).selectAll('*').remove();

      const graphData = this.graphData;
      const nodes = graphData.nodes;
      const edges = graphData.graphs[0].edges;

      const cardElement = this.$el.parentElement;

      const width = cardElement.clientWidth;
      const height = cardElement.clientHeight;

      // Forming a hierarchy for d3.tree()
      const rootNodeId = edges.find(edge => edge.graphEdgeType === 'ROOT').tailEntityId;
      const root = d3.hierarchy({ children: this.buildTree(nodes, edges, rootNodeId) });

      const treeLayout = d3.tree()
        .size([height, width])
        .separation((a, b) => (a.parent === b.parent ? 1 : 1) / a.depth); // separation: function that determines the distance between nodes.

      // Applying parameters to treeLayout
      const treeData = treeLayout(root);
      const nodesData = treeData.descendants();

      // Optionally: Adjust node positions if needed
      nodesData.forEach((d, i) => {
        d.y = d.depth * 320; // Change the value as needed
      });

      const linksData = treeData.links().map(link => {
        const sourceEntityId = link.source.data.entityId || rootNodeId;

        const edge = edges.find(e => {
          const targetEntityId = link.target.data.entityId;
          return (
            (e.headEntityId === targetEntityId && e.tailEntityId === sourceEntityId) ||
            (e.headEntityId === sourceEntityId && e.tailEntityId === targetEntityId)
          );
        });

        const edgeType = edge ? edge.graphEdgeType : null;
        const edgeColor = this.getEdgeColor(edgeType);

        return {
          ...link,
          edgeColor,
        };
      });

      const svg = d3.select(this.$refs.graphContainer)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', `translate(130,0)`);

      // Define the curve generator
      const curveGenerator = d3.line()
        .x(d => d[0])
        .y(d => d[1])
        .curve(d3.curveBasis);

      svg.selectAll('.link')
        .data(linksData)
        .enter()
        .append('path')
        .attr('class', 'link')
        .style('stroke', d => d.edgeColor)
        .style('stroke-width', 2)
        .style('fill', 'none')
        .attr('d', d => {
          const pathData = this.getPathData(d, this.nodeWidth);
          return curveGenerator(pathData.map(point => [point[0] * this.scale, point[1] * this.scale]));
        })
        .attr('marker-end', function (d, i) {
          // Create a unique ID for each marker
          const markerId = 'arrowhead-' + i;

          // Define a new marker for each path
          svg.append('defs')
            .append('marker')
            .attr('id', markerId)
            .attr('viewBox', '0 0 10 10')
            .attr('refX', 9)
            .attr('refY', 5)
            .attr('markerWidth', 8)
            .attr('markerHeight', 8)
            .attr('orient', 'auto')
            .append('path')
            .attr('d', 'M 0 0 L 10 5 L 0 10')
            .style('fill', d.edgeColor); // Set the marker color for each path

          // Return the reference to the unique marker
          return `url(#${ markerId })`;
        });


      const node = svg.selectAll('.node')
        .data(nodesData)
        .enter()
        .append('g')
        .attr('id', (d, i) => d.data.entityId)
        .attr('class', 'node')
        .attr('transform', (d, i) => {
          return `translate(${ d.y },${ d.x })`;
        });

      const self = this;
      node.append('foreignObject')
        .attr('width', this.nodeWidth)
        .attr('height', this.nodeHeight)
        .attr('x', -this.nodeWidth / 2)
        .attr('y', -this.nodeHeight / 2)
        .attr('overflow', 'visible')
        .append('xhtml:div')
        .html(d => {
          if (d.depth === 0) {
            const rootMission = self.missionsData.find(item => item.id === rootNodeId);
            const imgSrc = this.iconsUri[rootNodeId] ? this.iconsUri[rootNodeId] : '';
            const reward = rootMission && rootMission.rewards && rootMission.rewards[0] ? rootMission.rewards[0] : null;
            const rewardValue = reward ? reward.rewardValue : '';
            const rewardName = reward ? reward.name : '';
            const missionScheduling = rootMission.scheduling;

            return htmlWithStyles(rootMission.name, rewardName, rewardValue, imgSrc, missionScheduling, rootMission.id, true);
          } else {
            const mission = self.missionsData.find(item => item.id === d.data.entityId);
            const name = d.data.missionData && d.data.missionData.name ? d.data.missionData.name : '';
            const imgSrc = this.iconsUri[d.data.entityId] ? this.iconsUri[d.data.entityId] : '';
            const reward = mission && mission.rewards && mission.rewards[0] ? mission.rewards[0] : null;
            const rewardValue = reward ? reward.rewardValue : '';
            const rewardName = reward ? reward.name : '';
            const missionScheduling = mission.scheduling;

            return htmlWithStyles(name, rewardName, rewardValue, imgSrc, missionScheduling, mission.id, true);
          }
        });

      node.on('click', (event, d) => {
        const entityId = d.data.entityId ? d.data.entityId : rootNodeId;
        this.showModal(entityId);
      });
    },
    getPathData(d, nodeWidth) {
      if (!d || !d.source || !d.target) {
        return {};
      }

      const source = [d.source.y, d.source.x];
      const target = [d.target.y, d.target.x];


      const adjustedSource = [source[0] + nodeWidth / 2, source[1]];
      const adjustedTarget = [target[0] - nodeWidth / 2, target[1]];

      const controlPoint1 = [
        (adjustedSource[0] + adjustedTarget[0]) / 2,
        adjustedSource[1]
      ];

      const controlPoint2 = [
        (adjustedSource[0] + adjustedTarget[0]) / 2,
        adjustedTarget[1]
      ];

      return [adjustedSource, controlPoint1, controlPoint2, adjustedTarget];
    },
    onMouseWheel(event) {
      event.preventDefault();
    },
    updateGraph() {
      const cardElement = this.$el.parentElement;
      const cardWidth = cardElement.clientWidth;
      const cardHeight = cardElement.clientHeight;

      d3.selectAll('.node')
        .attr('transform', d => `translate(${ d.y * this.scale },${ d.x * this.scale })`);

      d3.selectAll('.link')
        .attr('d', d => {
          const pathData = this.getPathData(d, this.nodeWidth);
          return d3.line()
            .curve(d3.curveBasis)
            .x(d => d[0] * this.scale)
            .y(d => d[1] * this.scale)(pathData);
        });

      d3.select(this.$refs.graphContainer)
        .select('svg')
        .attr('width', cardWidth)
        .attr('height', cardHeight);
    },
    onDragStart(event) {
      this.isDragging = true;
      this.dragStartCoords = { x: event.clientX, y: event.clientY };
    },
    onDragging(event) {
      if (this.isDragging) {
        const dx = (event.clientX - this.dragStartCoords.x) / this.scale;
        const dy = (event.clientY - this.dragStartCoords.y) / this.scale;
        this.dragStartCoords = { x: event.clientX, y: event.clientY };

        d3.selectAll('.node')
          .attr('transform', function (d) {
            return `translate(${ (d.y += dx) },${ (d.x += dy) })`;
          });
        this.updateGraph();
      }
    },
    onDragEnd() {
      this.isDragging = false;
    },
    getEdgeColor(edgeType) {
      switch (edgeType) {
        case 'MUST':
          return '#6FCF97';
        case 'SHOULD':
          return 'rgb(238, 187, 0)';
        case 'MUST-NOT':
          return '#EB5757';
        default:
          return '#ccc';
      }
    },
    showModal(entityId) {
      this.isShowModal = true;
      this.entityId = entityId;

      const mission = this.missionsData.find(mission => mission.id === entityId);
      this.modalTitle = `Name: ${ mission.name }`;

      const startDate = dateFormate(mission.scheduling.startDate).split(',')[0];
      const endDate = dateFormate(mission.scheduling.endDate).split(',')[0];

      this.messageGeneral = `
          Status: ${ mission.status } <br>
          Start date: ${ startDate } <br>
          End date: ${ endDate } <br>
      `;
    },
    closeModal() {
      this.isShowModal = false;
      this.entityId = null;
      this.modalTitle = '';
      this.messageGeneral = '';
    },
    goToAchievement() {
      this.$router.push({
        name: 'PreviewAchievement',
        params: {
          id: this.entityId,
        },
        query: {
          source: 'mission',
        },
      });
    },
    buildTree(nodes, edges, rootNodeId) {
      const buildTreeRecursive = (parentId = null) => {
        const children = edges
          .filter(edge => edge.headEntityId === parentId)
          .map(edge => {
            const childNode = nodes.find(node => node.entityId === edge.tailEntityId);
            const missionData = this.missionsData.find(mission => mission.id === childNode.entityId);

            return {
              ...childNode,
              missionData: { ...missionData, icon: this.iconsUri[missionData.id] },
              children: buildTreeRecursive(childNode.entityId),
            };
          });
        return children.length ? children : null;
      };

      return buildTreeRecursive(rootNodeId);
    },
    async loadIcons() {
      const iconUris = {};

      for (const mission of this.missionsData) {
        const iconId = mission.icon;
        if (iconId) {
          const file = await this.handleGetFileObjects_item({ idArray: [iconId] });
          if (file && file.length > 0) {
            iconUris[mission.id] = file[0].uri;
          } else {
            iconUris[mission.id] = null;
          }
        } else {
          iconUris[mission.id] = null;
        }
      }

      this.$set(this, 'iconsUri', iconUris);
    },
  },
};
</script>

<style scoped lang="scss">
.missions-preview-graph {
  width: 100%;
  height: 100%;

  .graph-container {
    height: 100%;
    width: 100%;
  }
}

.node text {
  user-select: none;
}
</style>
